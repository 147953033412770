import {Injectable} from '@angular/core';

import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {DataListResponse} from '../models/data-list-response';
import {ElectroDeviceCategory} from '../models/electro-device-category';
import {HelperService} from './helper.service';
import {DataResponse} from '../models/data-response';
import {Page} from '../models/page';

@Injectable({
  providedIn: 'root'
})
export class ElectroDeviceCategoryService {

  endPoint = `/electro-device-categories`;

  list: Observable<DataListResponse<ElectroDeviceCategory>> = null;

  constructor(private http: HttpClient) {
  }

  getList(page: Page): Observable<DataListResponse<ElectroDeviceCategory>> {
    return this.http.get<DataListResponse<ElectroDeviceCategory>>(`${HelperService.getAPIUrl(this.endPoint)}`, {
      params: new HttpParams()
        .set('pageNumber', page.pageNumber.toString())
        .set('sortOrder', page.sortOrder)
        .set('sortColumn', page.sortColumn)
        .set('searchCriteria', page.searchCriteria)
    });
  }

  getNameList(): Observable<DataListResponse<ElectroDeviceCategory>> {
    return this.http.get<DataListResponse<ElectroDeviceCategory>>(`${HelperService.getAPIUrl(this.endPoint)}/names`, {
      params: new HttpParams()
    });
  }

  get(id): Observable<DataResponse<ElectroDeviceCategory>> {
    return this.http.get<DataResponse<ElectroDeviceCategory>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }

  create(country: ElectroDeviceCategory): Observable<DataListResponse<ElectroDeviceCategory>> {
    return this.http.post<DataListResponse<ElectroDeviceCategory>>(HelperService.getAPIUrl(this.endPoint), country);
  }

  update(value: ElectroDeviceCategory): Observable<DataListResponse<ElectroDeviceCategory>> {
    return this.http.put<DataListResponse<ElectroDeviceCategory>>(`${HelperService.getAPIUrl(this.endPoint)}/${value.id}`, value);
  }

  delete(id: number): Observable<DataListResponse<ElectroDeviceCategory>> {
    return this.http.delete<DataListResponse<ElectroDeviceCategory>>(`${HelperService.getAPIUrl(this.endPoint)}/${id}`);
  }
}
