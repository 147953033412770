export class InventorySearchModel {
  deviceCategory = '';
  deviceManufacturer = '';
  deviceModel = '';
  serialNumber = '';
  barcode = '';
  gtin = '';
  uvp = '';
  demarcation = '';
  freeText1 = '';
  freeText2 = '';
  freeText3 = '';
}
