export class Dashboard {
  legalEntityCount = 0;
  hotelCount = 0;
  roomCount = 0;
  instanceCount = 0;
  companyCount = 0;
  userCount = 0;
  roomGuestCount = 0;
  buildingCount = 0;
  inspectionCount = 0;
  deviceCount = 0;
  inspectedDevicesCount = 0;
  deviceCategoryCount = 0;
  deviceManufacturerCount = 0;
  dangeriousDevicesCount = 0;
  deviceModelCount = 0;
  deviceTypeCount = 0;
}
