import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Page} from '../../../models/page';
import {ElectroDeviceService} from '../../../services/electro-device.service';
import {ElectroDevice} from '../../../models/electro-device';
import {ToastrService} from 'ngx-toastr';
import {Table} from '../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {AuthenticationInterface, AuthenticationService} from '../../../services/authentication.service';
import {DatatableComponent} from '@swimlane/ngx-datatable/release/components/datatable.component';
import {ElectroDeviceManufacturer} from '../../../models/electro-device-manufacturer';
import {ElectroDeviceType} from '../../../models/electro-device-type';
import {DataResponse} from '../../../models/data-response';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {environment} from '../../../../environments';
import {ElectroDeviceTypeService} from '../../../services/electro-device-type.service';
import {ElectroDeviceManufacturerService} from '../../../services/electro-device-manufacturer.service';
import {ElectroDeviceCategory} from '../../../models/electro-device-category';
import {ElectroDeviceCategoryService} from '../../../services/electro-device-category.service';
import {NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';

@Component({
  selector: 'app-list-electro-device',
  templateUrl: './list-electro-device.component.html',
  styleUrls: ['./list-electro-device.component.css']
})
export class ListElectroDeviceComponent implements OnInit, OnDestroy, AuthenticationInterface {

  @ViewChild(DatatableComponent, {static: false}) tableDC: DatatableComponent;
  temp = [];

  selectedElectroDeviceId: number = null;

  uploadSchemas = [
    'Geschirrspüler',
    'Waschmaschinen',
    'Waschtrockner',
    'Fernsehgeräte, Monitore und sonstige Displays',
    'Kühl- und Gefriergeräte und Weinlagerschränke',
    'Kühl- und Gefriergeräte mit Direktverkaufsfunktion, Getränkekühler und Speiseeis-Gefriermaschinen',
    'Reifen',
    'Lichtquellen',
    'Luftkonditionierer',
    'Haushaltsbacköfen',
    'Dunstabzugshauben',
    'Haushaltswäschetrockner',
    'Einzelraumheizgeräte',
    'Gewerbliche Kühllagerschränke',
    'Wohnraumlüftungsanlagen',
    'Festbrennstoffkessel',
    'Verbundanlagen mit einem Festbrennstoffkessel',
    'Raumheizgeräte/Kombiheizgeräte',
    'Verbundanlagen mit Raumheizgeräten/Kombiheizgeräten',
    'Temperaturregler für Raumheizgeräte',
    'Solareinrichtungen für Raumheizgeräte',
    'Warmwasserbereiter',
    'Verbundanlagen mit Warmwasserbereitern',
    'Warmwasserspeicher für Warmwasserbereiter',
    'Solareinrichtungen für Warmwasserbereiter',
  ];

  sortList = [
    {id: 0, name: 'Modellkennung', type: 'asc', order: 'name'},
    {id: 1, name: 'Modellkennung', type: 'desc', order: 'name'},
    {id: 2, name: 'Name oder Handelsmarke des Lieferanten', type: 'asc', order: 'manufacturer.name'},
    {id: 3, name: 'Name oder Handelsmarke des Lieferanten', type: 'desc', order: 'manufacturer.name'},
    {id: 4, name: 'Gerättyp', type: 'asc', order: 'type.name'},
    {id: 5, name: 'Gerättyp', type: 'desc', order: 'type.name'},
    {id: 6, name: 'Energieeffizienzklasse', type: 'asc', order: 'energyClass'},
    {id: 7, name: 'Energieeffizienzklasse', type: 'desc', order: 'energyClass'}
  ];

  dangeriousList = [
    {id: 0, name: 'Alle'},
    {id: 1, name: 'Nur gefährliche Geräte'},
    {id: 2, name: 'Kein gefährliche Geräte'},
  ];

  totalPerPageList = [
    {number: 10, selected: true},
    {number: 25, selected: false},
    {number: 50, selected: false},
    {number: 100, selected: false},
  ];

  energyClassList = [
    {id: 0, name: 'Keine'},
    {id: 1, name: 'A'},
    {id: 2, name: 'B'},
    {id: 3, name: 'C'},
    {id: 4, name: 'D'},
    {id: 5, name: 'E'},
    {id: 6, name: 'F'},
    {id: 7, name: 'G'}
  ];

  // CREATE
  protectionClassList = [
    {id: 1, name: 'Schutzklasse I'},
    {id: 2, name: 'Schutzklasse II'},
    {id: 3, name: 'Schutzklasse III'},
  ];

  createEnergyClassList = [
    {id: 0, name: 'Keine'},
    {id: 1, name: 'A'},
    {id: 2, name: 'B'},
    {id: 3, name: 'C'},
    {id: 4, name: 'D'},
    {id: 5, name: 'E'},
    {id: 6, name: 'F'},
    {id: 7, name: 'G'}
  ];

  cacheManufacturerList = new Array<ElectroDeviceManufacturer>();
  cacheTypeList = new Array<ElectroDeviceType>();
  cacheCategoryList = new Array<ElectroDeviceCategory>();

  createManufacturerList = new Array<ElectroDeviceManufacturer>();
  createManufacturerListLoading = false;

  createTypeList = new Array<ElectroDeviceType>();
  createTypeListLoading = false;

  createCategoryList = new Array<ElectroDeviceCategory>();
  createCategoryListLoading = false;

  createCacheTypeList = new Array<ElectroDeviceType>();
  createCacheManufacturerList = new Array<ElectroDeviceManufacturer>();

  newOneObject = new ElectroDevice();
  editOneObject = new ElectroDevice();

  createOneWaiting = false;
  // CREATE

  manufacturerList = new Array<ElectroDeviceManufacturer>();
  manufacturerListLoading = false;

  typeList = new Array<ElectroDeviceType>();
  typeListLoading = false;

  categoryList = new Array<ElectroDeviceCategory>();
  categoryListLoading = false;

  optionsInput: InputFileOptions = {
    multiple: false,
    accept: [MineTypeEnum.Text_Csv],
    disableMultipart: false
  };

  newObject = new ElectroDevice();
  createWaiting = false;

  // COMPARE
  compareList = new Array<ElectroDevice>();
  totalCompareAllowed = 3;
  totalCompareSelected = 0;

  singleEnergyClassList = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

  compareTableFirstRow = [
    'VEDIN',
    'Gerätekategorie',
    'Gerätehersteller',
    'Gerätemodell',
    'Seriennummer',
    'Energieeffizienzklasse',
    'Gefährliches Gerät',
    'Steuerungstyp',
    'Seriennummer',
    'Verbrauch (jährlich / kWh)',
    'Eprel Reg.',
  ];

  compareModalOptions = {
    title: 'Vergleiche Geräte',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '900px',
    onOpen: function () {
    },
    preConfirm: () => {
      return new Promise((resolve) => {
        if (this.newObject.fileName != null) {
          this.createWaiting = true;
          this.create(resolve);
          this.createWaiting = false;
        }
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };
  // COMPARE

  createModalOptions = {
    title: 'Import Geräte (CSV-Datei)',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Importieren',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
    },
    preConfirm: () => {
      return new Promise((resolve) => {
        if (this.newObject.fileName != null) {
          this.createWaiting = true;
          this.create(resolve);
          this.createWaiting = false;
        }
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  createOneModalOptions = {
    title: 'Elektrogerät hinzufügen',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Erstellen',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {

    },
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createOneWaiting = true;
        this.createOne(resolve);
        this.createOneWaiting = false;
      });
    },
    allowOutsideClick: () => !this.createOneWaiting
  };
  editOneModalOptions = {
    title: 'Elektrogerät bearbeiten',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Speichern',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
    },
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createOneWaiting = true;
        this.updateOne(resolve);
        this.createOneWaiting = false;
      });
    },
    allowOutsideClick: () => !this.createOneWaiting
  };

  table: Table = {
    columnMode: ColumnMode.standard,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Kategorie', prop: 'category.name'},
      {name: 'Typ', prop: 'type.name'},
      {name: 'Hersteller', prop: 'manufacturer.name'},
      {name: 'Modell', prop: 'name'}
    ],
    rowList: new Array<ElectroDevice>()
  };

  searchModel = {
    'deviceCategory': 0,
    'deviceType': 0,
    'deviceManufacturer': 0,
    'deviceModel': '',
    'dangerious': 0,
    'energyClass': 0
  };

  sortModel = {
    selectedTotalPerPage: 25,
    selectedSortOrder: 3
  };
  isImporting = false;
  searching = false;

  // CREATE
  yesNoList = [
    {id: 0, name: 'Nein', value: false},
    {id: 1, name: 'Ja', value: true},
  ];

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  uploadAllTriggered = false;

  imageOptionsInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Image, MineTypeEnum.Image_Png],
    disableMultipart: false
  };

  imageImageList: any;
  imageUploadModalOptions = {
    title: 'Bild Hochladen',
    cancelButtonColor: '#111',
    showCancelButton: false,
    showConfirmButton: false,
    showLoaderOnConfirm: true,
    focusCancel: true,
    allowOutsideClick: () => false,
    preConfirm: () => {
    },
    onClose: () => {
      this.galleryImages = [];
      this.imageUploader.removeAllFromQueue();
    },
    width: '1000px'
  };



  electroDeviceDocumentPdfModalOptions = {
    title: 'Elektro Geräte / Dokument importieren',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Importieren',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
    },
    preConfirm: () => {
    },
    allowOutsideClick: () => false
  };

  constructor(private electroDeviceService: ElectroDeviceService,
              private electroDeviceManufacturerService: ElectroDeviceManufacturerService,
              private electroDeviceTypeService: ElectroDeviceTypeService,
              private electroDeviceCategoryService: ElectroDeviceCategoryService,
              public uploader: HttpClientUploadService,
              public readonly swalTargets: SwalPartialTargets,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              public imageUploader: HttpClientUploadService) {
    this.table.page.pageNumber = 0;
    this.table.page.size = 25;
    this.table.loading = true;
  }

  ngOnInit(): void {

    this.resetCreateObject();

    this.uploader.queue = [];

    this.uploader.onCancel$.subscribe(
      (data: FileItem) => {
      });

    this.uploader.onDropError$.subscribe(
      (err) => {
      });

    this.uploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.uploader.onSuccess$.subscribe(
      (data: any) => {

        this.newObject.fileName = data.body.data.fileName;

        data.item.remove();
      }
    );

    this.getManufacturerList();
    this.getTypeList();
    this.getCategoryList();

    this.getPage(0);
  }

  ngOnDestroy(): void {
  }

  private getManufacturerList() {
    this.electroDeviceManufacturerService.getNameList().subscribe(value => {

      this.manufacturerList = [...value.data];
      this.createManufacturerList = [...value.data];

      this.manufacturerList.unshift({'id': 0, 'name': 'Alle'});
      this.cacheManufacturerList = this.manufacturerList;
    });
  }

  private getTypeList() {
    this.electroDeviceTypeService.getNameList().subscribe(value => {
      this.typeList = [...value.data];
      this.createTypeList = [...value.data];
      this.typeList.unshift({'id': 0, 'name': 'Alle'});
      this.cacheTypeList = this.typeList;
    });
  }

  private getCategoryList() {
    this.electroDeviceCategoryService.getNameList().subscribe(value => {
      this.categoryList = [...value.data];
      this.createCategoryList = [...value.data];
      this.categoryList.unshift({'id': 0, 'name': 'Alle'});
      this.cacheCategoryList = this.categoryList;
    });
  }

  getPage(pageNumber) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.page.pageNumber = pageNumber;
    this.electroDeviceService.getList(this.table.page).subscribe(dataResponse => {

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message, 'Error');
      }

      if (dataResponse.page !== null) {
        this.table.page = dataResponse.page;
        this.table.page.totalPages = this.getTotalPages();
      }
      // const data = dataResponse.data;
      // this.table.rowList = this.table.rowList.concat(data);

      const data = dataResponse.data;

      this.temp = [...data];
      this.table.rowList = data;
      this.table.loading = false;

    });
  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getPage(0);
  }

  deleteData(row) {

  }

  getDataUrl(id: number) {
    return `${this.electroDeviceService.endPoint}/${id}`;
  }

  getEditUrl(id: number) {
    return `${this.electroDeviceService.endPoint}/${id}/edit`;
  }

  onActivate(event) {

  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  onDelete(rowList) {
    this.delete(rowList);
  }

  delete(rowList: Array<ElectroDevice>) {

    const that = this;

    rowList.forEach(function (row) {
      that.electroDeviceService.delete(row.id).subscribe(resp => {
        if (resp.status === 'success') {
          that.table.rowList = that.table.rowList.filter(function (obj) {
            return obj.id !== row.id;
          });
          // that.toastr.success(resp.message);
          that.table.selected = [];
        } else {
          that.toastr.error(resp.message);
        }
      });

    });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  updateFilter(event) {

    const val = event.target.value.toLowerCase();

    // filter our data
    this.table.rowList = this.temp.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 ||
        d.address.country.name.toLowerCase().indexOf(val) !== -1 ||
        d.address.city.toLowerCase().indexOf(val) !== -1 ||
        d.customerNumber.toLowerCase().indexOf(val) !== -1 ||
        d.businessId.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.tableDC.offset = 0;
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: number = null): boolean {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  create(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.isImporting = true;
    this.electroDeviceService.create(this.newObject).subscribe((dataResponse: DataResponse<ElectroDevice>) => {

        this.createWaiting = false;
        this.getPage(0);
        this.newObject = new ElectroDevice();

        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message);
        }

        this.createWaiting = false;

        if (dataResponse.status === 'success') {
          this.newObject = new ElectroDevice();
          this.getPage(0);
        }

        this.isImporting = false;

        resolve();
      },
      error => {
        this.toastr.error(error.toString());
        this.createWaiting = false;
        this.isImporting = false;
      });
  }

  resetCreateObject() {
    this.newOneObject = new ElectroDevice();
  }

  createOne(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.electroDeviceService.createOne(this.newOneObject).subscribe((dataResponse: DataResponse<ElectroDevice>) => {

        this.createOneWaiting = false;
        this.getPage(0);
        this.resetCreateObject();
        this.getPage(0);

        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message);
        }

        this.createOneWaiting = false;

        if (dataResponse.status === 'success') {
          this.resetCreateObject();
          this.getPage(0);
        }

        resolve();
      },
      error => {
        this.toastr.error(error.toString());
        this.createOneWaiting = false;
      });
  }

  removeDocument() {
    this.newObject.fileName = null;
  }

  canCreate() {
    return this.newObject.fileName;
  }

  upload(item: FileItem) {
    item.upload({
      method: 'POST',
      url: this.electroDeviceService.uploadUrl()
    });
  }

  activeRemoveAllBtn(): boolean {
    return this.uploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.uploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.uploader.queue.some((item: FileItem) => item.uploadInProgress);
  }

  onBeforeOpen($event: BeforeOpenEvent) {
  }

  getMediaResource(name: string) {
    return environment.apis.backend + '/media/uploads/' + name;
  }

  energyClassText(energyClass: number) {

    const energyClasses = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

    if (!energyClass) {
      energyClass = 0;
    }

    return energyClasses[energyClass];
  }

  getEnergyClassClass(energyClass: number) {

    const energClassGroup = ['Unbekannt', 'a', 'a', 'a', 'a', 'b', 'c', 'd', 'e', 'f', 'g'];

    return 'energy-class-' + energClassGroup[energyClass];
  }

  search(pageNumber = 0) {

    this.table.loading = true;
    this.table.page.sortColumn = this.sortList[this.sortModel.selectedSortOrder].order;
    this.table.page.sortOrder = this.sortList[this.sortModel.selectedSortOrder].type;
    this.table.page.size = this.sortModel.selectedTotalPerPage;
    this.table.page.pageNumber = pageNumber;

    if (this.searchModel.deviceCategory === null) {
      this.searchModel.deviceCategory = 0;
    }

    if (this.searchModel.deviceManufacturer === null) {
      this.searchModel.deviceManufacturer = 0;
    }

    if (this.searchModel.deviceType === null) {
      this.searchModel.deviceType = 0;
    }

    if (this.searchModel.energyClass === null) {
      this.searchModel.energyClass = 0;
    }

    if (this.searchModel.dangerious === null) {
      this.searchModel.dangerious = 0;
    }

    this.electroDeviceService.getFilteredList(this.table.page, this.searchModel).subscribe(dataResponse => {

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message, 'Error');
      }

      if (dataResponse.page !== null) {
        this.table.page = dataResponse.page;
        this.table.page.totalPages = this.getTotalPages();
      }

      const data = dataResponse.data;

      this.temp = [...data];
      this.table.rowList = data;
      this.table.loading = false;

    });
  }

  getPageArray() {

    this.table.page.totalPages = this.getTotalPages();

    if (this.table.page.totalPages <= 1) {
      return [];
    }

    const pages = [];
    let currentPage = this.table.page.pageNumber;

    const maxOffset = this.table.page.totalPages < 10 ? this.table.page.totalPages : 10;

    if (currentPage >= this.table.page.totalPages - maxOffset) {
      currentPage = this.table.page.totalPages - maxOffset;
    }

    for (let i = currentPage; i < currentPage + maxOffset; i++) {
      pages.push(i);
    }

    return pages;
  }

  getTotalPages() {

    if (this.table.page.totalElements < this.table.page.size) {
      return 0;
    }

    const pageCount = this.table.page.totalElements / this.table.page.size;
    let totalPages = Math.floor(pageCount);

    if (pageCount > totalPages) {
      totalPages++;
    }

    return totalPages;
  }

  isCurrentPage(pageNumber: number) {
    return this.table.page.pageNumber === pageNumber ? 'active' : '';
  }

  gotoNextPage() {
    if (this.table.page.pageNumber + 1 < this.table.page.totalPages) {
      this.search(this.table.page.pageNumber + 1);
    }
  }

  gotoPreviousPage() {
    if (this.table.page.pageNumber > 0) {
      this.search(this.table.page.pageNumber - 1);
    }
  }

  gotoLastPage() {
    if (this.table.page.pageNumber + 1 < this.table.page.totalPages) {
      this.search(this.table.page.totalPages - 1);
    }
  }

  gotoFirstPage() {
    if (this.table.page.pageNumber > 0) {
      this.search(0);
    }
  }

  private updateOne(resolve: (value?: (PromiseLike<any> | any)) => void) {

    this.electroDeviceService.updateOne(this.editOneObject).subscribe((dataResponse: DataResponse<ElectroDevice>) => {

        this.createOneWaiting = false;
        this.getPage(0);
        this.editOneObject = new ElectroDevice();
        this.getPage(0);

        if (dataResponse.status === 'error') {
          this.toastr.error(dataResponse.message);
        }

        this.createOneWaiting = false;

        if (dataResponse.status === 'success') {
          this.editOneObject = new ElectroDevice();
          this.getPage(0);
        }

        resolve();
      },
      error => {
        this.toastr.error(error.toString());
        this.createOneWaiting = false;
      });
  }

  loadElectroDevice(id) {

    this.editOneObject = null;

    this.electroDeviceService.get(id).subscribe(response => {

      this.editOneObject = response.data;

      if (response.status === 'error') {
        this.toastr.error(response.message);
      }
    });
  }

  onSearchDeviceTypeChange($event: any) {


    if ($event === 0) {
      this.manufacturerList = this.cacheManufacturerList;
      return;
    }

    this.manufacturerList = this.cacheManufacturerList.filter((manufacturer: any) => {

      let result = false;

      if (!manufacturer.typeList) {
        return false;
      }

      manufacturer.typeList.forEach(function (value, index) {
        if (value.id === $event) {
          result = true;
          return false;
        }
      });

      return result;
    });

    this.searchModel.deviceManufacturer = 0;
  }

  onSearchDeviceCategoryChange($event: any) {

    if ($event === 0) {
      this.typeList = this.cacheTypeList;
      return;
    }

    this.searchModel.deviceManufacturer = 0;
    this.searchModel.deviceType = 0;

    this.typeList = this.cacheTypeList.filter((type: ElectroDeviceType) => {

      let result = false;

      if (!type.categoryList) {
        return false;
      }

      type.categoryList.forEach(function (value, index) {
        if (value.id === $event) {
          result = true;
          return true;
        }
      });

      return result;
    });
  }

  deleteSelectedDevice() {

    const that = this;

    if (!this.selectedElectroDeviceId) {
      return;
    }

    this.electroDeviceService.delete(this.selectedElectroDeviceId).subscribe(resp => {

      if (resp.status === 'success') {

        that.table.rowList = that.table.rowList.filter(function (obj) {
          return obj.id !== that.selectedElectroDeviceId;
        });

        // that.toastr.success(resp.message);
        that.table.selected = [];
      } else {
        that.toastr.error(resp.message);
      }
    });

  }


  onCreateSearchDeviceCategoryChange($event: any) {

    if ($event === 0) {
      this.createTypeList = this.createCacheTypeList;
      return;
    }

    this.createTypeList = this.createCacheTypeList.filter((type: ElectroDeviceType) => {

      let result = false;

      if (!type.categoryList) {
        return false;
      }

      type.categoryList.forEach(function (value, index) {
        if (value.id === $event) {
          result = true;
          return true;
        }
      });

      return result;
    });
  }


  onCreateSearchDeviceTypeChange($event: any) {

    if ($event === 0) {
      this.createManufacturerList = this.createCacheManufacturerList;
      return;
    }

    this.createManufacturerList = this.createCacheManufacturerList.filter((manufacturer: any) => {

      let result = false;

      if (!manufacturer.typeList) {
        return false;
      }

      manufacturer.typeList.forEach(function (value, index) {
        if (value.id === $event) {
          result = true;
          return false;
        }
      });

      return result;
    });

  }

  getGoogleSearchUrl(row) {

    let category = row.type.name;
    let manufacturer = row.manufacturer.name;
    let model = row.name;
    let queryCount = 0;
    let query = 'https://www.google.de/search?q=';

    category = category.trim().replace(' ', '+');
    manufacturer = manufacturer.trim().replace(' ', '+');
    model = model.trim().replace(' ', '+');

    if (category && category.toLowerCase() !== 'vorhanden') {
      query = `${query}${category}`;
      queryCount++;
    }

    if (manufacturer && manufacturer.toLowerCase() !== 'vorhanden') {
      query = `${query}+${manufacturer}`;
      queryCount++;
    }
    if (model && model.toLowerCase() !== 'vorhanden') {
      query = `${query}+${model}`;
      queryCount++;
    }
    return queryCount > 0 ? query : null;
  }

  isInCompareListExist(row) {
    let exists = false;

    this.compareList.forEach(function (inventory) {
      if (inventory.id === row.id) {
        exists = true;
      }
    });
    return exists;
  }

  addCompare(row) {
    const exists = this.isInCompareListExist(row);
    if (!exists && this.totalCompareSelected < this.totalCompareAllowed) {
      this.compareList.push(row);
      this.totalCompareSelected = this.compareList.length;
    }
  }

  deleteCompare(row: any) {

    this.compareList.forEach(function (inventory, index, compareList) {
      if (inventory.id === row.id) {
        compareList.splice(index, 1);
      }
    });

    this.totalCompareSelected = this.compareList.length;
  }

  toggleCompare(row) {
    if (this.isInCompareListExist(row)) {
      this.deleteCompare(row);
    } else {
      this.addCompare(row);
    }
  }

  clearCompareList() {
    this.compareList.splice(0);
    this.totalCompareSelected = 0;
  }

  uploadImageAll() {
    this.uploadAllTriggered = true;
    this.imageUploader.uploadAll({
      method: 'POST',
      url: this.electroDeviceService.uploadImageUrl(this.selectedElectroDeviceId.toString())
    });
  }

  uploadImage(item: FileItem) {
    item.upload({
      method: 'POST',
      url: this.electroDeviceService.uploadImageUrl(this.selectedElectroDeviceId.toString())
    });
  }

  getAvatar(id) {
    return `${environment.apis.backend}/media/uploads/electro-devices/images/avatar/avatar-${id}.jpeg`;
  }

  onAvatarError(event) {
    event.target.src = './assets/images/devices/no-image.png';
  }

  selectElectroDevice(electroDevice: ElectroDevice) {
    this.electroDeviceService.registerCurrentElectroDevice(electroDevice);
  }

  getElectroDeviceDocumentUrl(id) {
    return `${environment.apis.backend}/media/uploads/electro-devices/documents/document-${id}.pdf`;
  }
}
