import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationInterface, AuthenticationService} from '../../../services/authentication.service';
import {Table} from '../../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {Page} from '../../../models/page';
import {Report} from '../../../models/report';
import {ReportSearchModel} from '../../../models/report-search-model';
import {Company} from '../../../models/company';
import {Building} from '../../../models/building';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ElectroDeviceInventoryItemService} from '../../../services/electro-device-inventory-item.service';
import {ElectroDeviceInventoryItem} from '../../../models/electro-device-inventory-item';
import {CompanyService} from '../../../services/company/company.service';
import {BuildingService} from '../../../services/building/building.service';
import {ElectroDeviceCategory} from '../../../models/electro-device-category';
import {ElectroDeviceManufacturer} from '../../../models/electro-device-manufacturer';
import {environment} from '../../../../environments';

@Component({
  selector: 'app-list-report',
  templateUrl: './list-report.component.html',
  styleUrls: ['./list-report.component.css']
})
export class ListReportComponent implements OnInit, OnDestroy, AuthenticationInterface {

  searching = false;
  tempList: Array<Report>;
  companyList: Array<Company>;
  companyListLoading = false;
  buildingList: Array<Building>;
  cachedBuildingList = new Array<Building>();
  buildingListLoading = false;

  deviceCategoryList: Array<ElectroDeviceCategory>;
  deviceCategoryListLoading = false;

  deviceManufacturerList: Array<ElectroDeviceManufacturer>;
  deviceManufacturerListLoading = false;

  exporting = false;
  reportInfoReady = false;
  exportFile = null;

  exportCSVModalOptions = {
    title: 'Bericht Exportieren',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Export',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
    },
    preConfirm: () => {
    },
    allowOutsideClick: () => !this.exporting
  };

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Kategorie', prop: 'deviceType'},
      {name: 'Hersteller', prop: 'deviceManufacturer'},
      {name: 'Modell', prop: 'deviceModel'},
      {name: 'Serien-Nr.', prop: 'serialNumber'},
      {name: 'Standort/Zimmer', prop: 'location'}
    ],
    rowList: new Array<ElectroDeviceInventoryItem>()
  };

  protectionClassList = [
    {id: 1, name: 'Schutzklasse I'},
    {id: 2, name: 'Schutzklasse II'},
    {id: 3, name: 'Schutzklasse III'},
  ];

  searchModel = new ReportSearchModel();

  sortModel = {
    selectedTotalPerPage: 500,
    selectedSortOrder: 3
  };

  totalPerPageList = [
    {number: 500, selected: true},
    {number: 1000, selected: false},
    {number: 2000, selected: false},
    {number: 5000, selected: false},
    {number: 10000, selected: false},
  ];

  searchCriteriaList = [
    {id: 0, name: 'Alle'},
    {id: 1, name: 'xxxxxx'},
  ];

  constructor(public readonly swalTargets: SwalPartialTargets,
              private authenticationService: AuthenticationService,
              private electroDeviceInventoryItemService: ElectroDeviceInventoryItemService,
              private companyService: CompanyService,
              private buildingService: BuildingService) {

    this.table.page.pageNumber = 0;
    this.table.page.size = 25;
    this.table.loading = true;
    this.tempList = new Array<Report>();
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.loadCompanyList();
    this.loadBuildingList();
    this.loadElectroDeviceSearchInfo();
    this.search(0);
  }

  loadCompanyList() {
    this.companyListLoading = true;
    this.companyService.getNameList().subscribe(resp => {
      this.companyList = resp.data;
      this.companyListLoading = false;
    });
  }

  loadBuildingList() {
    this.buildingListLoading = true;
    this.buildingService.getNameList().subscribe(resp => {
      this.buildingList = resp.data;
      this.buildingListLoading = false;
    });
  }

  loadElectroDeviceSearchInfo() {
    this.deviceCategoryListLoading = true;
    this.electroDeviceInventoryItemService.getSearchInfoList().subscribe(resp => {
      this.deviceCategoryList = resp.data.deviceTypeList;
      this.deviceManufacturerList = resp.data.deviceManufacturerList;
      this.deviceCategoryListLoading = false;
    });
  }

  onSearchDeviceCategoryChange($event: any) {
  }

  search(pageNumber: any, isLoading = true) {

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = isLoading;
    if (this.table.page) {
      this.table.page.pageNumber = pageNumber;
    }

    this.electroDeviceInventoryItemService.search(this.searchModel, this.table.page).subscribe(dataResponse => {
      this.table.page = dataResponse.page;
      const data = dataResponse.data;
      this.table.rowList = [...data];
      this.table.loading = false;
    });
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  getTotalPages() {

    if (this.table.page.totalElements < this.table.page.size) {
      return 0;
    }

    const pageCount = this.table.page.totalElements / this.table.page.size;
    let totalPages = Math.floor(pageCount);

    if (pageCount > totalPages) {
      totalPages++;
    }

    return totalPages;
  }

  isCurrentPage(pageNumber: number) {
    return this.table.page.pageNumber === pageNumber ? 'active' : '';
  }

  gotoNextPage() {
    if (this.table.page.pageNumber + 1 < this.table.page.totalPages) {
      this.search(this.table.page.pageNumber + 1);
    }
  }

  gotoPreviousPage() {
    if (this.table.page.pageNumber > 0) {
      this.search(this.table.page.pageNumber - 1);
    }
  }

  gotoLastPage() {
    if (this.table.page.pageNumber + 1 < this.table.page.totalPages) {
      this.search(this.table.page.totalPages - 1);
    }
  }

  gotoFirstPage() {
    if (this.table.page.pageNumber > 0) {
      this.search(0);
    }
  }

  onSearchCompanyChange($event: any) {

    this.cachedBuildingList = this.buildingList.filter((building: Building) => {

      let result = false;

      if ($event.length > 0) {
        $event.forEach(function (companyId) {
          if (building.company.id === companyId) {
            result = true;
            return false;
          }
        });

      }
      return result;
    });

    this.searchModel.buildings = [];
  }

  onSearchBuildingChange($event: any) {

  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  onBeforeOpen($event: BeforeOpenEvent) {
  }

  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr((size * -1), size);
  }


  isGranted(role: string, roleType: string = null, rolePermissionType: number = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  onActivate(event: any) {

  }

  onSort(event) {

    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;

    this.search(0, false);
  }

  onPageSizeChange($event: any) {
    this.table.page.size = $event.number;
    this.searchModel.size = $event.number;
    this.search(0);
  }

  generateReport() {

  }

  getReportName(row: any) {

    const vedin = row.vedin;
    const buildingNumber = this.padLeft(row.electroDeviceInventory.building.internalNumber, '0', 2);
    const customerNumber = row.electroDeviceInventory.building.company.customerNumber;
    const barcode = row.barcode;

    return `${environment.apis.backend}/media/uploads/devices/check-reports/${vedin}_${customerNumber}_${buildingNumber}_${barcode}.pdf`;
  }

  export(type: string) {

    this.exportFile = null;
    this.exporting = true;

    const self = this;

    if (type === 'csv') {
      setTimeout(function () {
        self.reportInfoReady = true;
      }, 2000, self);

      this.electroDeviceInventoryItemService.export(this.searchModel).subscribe(resp => {
        this.exporting = false;
        this.exportFile = resp.data.fileName;
      });

    }
  }

  getExportFile() {
    return this.exportFile ? `${environment.apis.backend}/media/temp/reports/${this.exportFile}` : 'no-file-found';
  }

  cancelExport() {
    this.swalTargets.cancelButton().click();
    this.exporting = false;
    this.exportFile = null;
  }
}
