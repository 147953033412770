import {ElectroDeviceCategory} from './electro-device-category';

export class ElectroDeviceType {
  id: number;
  name: string;
  createdAt?: string;
  updatedAt?: string;
  eprelTypeName?: string;
  categoryList?: ElectroDeviceCategory[];
}
