import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService} from '../../services/authentication.service';
import {Table} from '../../models/table';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {Page} from '../../models/page';
import {ElectroDeviceTesting} from '../../models/electro-device-testing';
import {ElectroDeviceTestingService} from '../../services/electro-device-testing.service';
import {ToastrService} from 'ngx-toastr';
import {BeforeOpenEvent, SwalPartialTargets} from '@sweetalert2/ngx-sweetalert2';
import {ScannerComponent} from '../scanner/scanner.component';
import {Company} from '../../models/company';
import {ElectroDeviceInventoryItem} from '../../models/electro-device-inventory-item';
import {CompanyService} from '../../services/company/company.service';
import {BuildingService} from '../../services/building/building.service';
import {Building} from '../../models/building';
import {QuaggaJSResultObject} from '@ericblade/quagga2';
import {DataResponse} from '../../models/data-response';
import {ElectroDeviceInventory} from '../../models/electro-device-inventory';
import {ElectroDeviceTestingItem} from '../../models/electro-device-testing-item';
import {ElectroDeviceTestingItemService} from '../../services/electro-device-testing-item.service';
import {ElectroDeviceTestingDeviceService} from '../../services/electro-device-testing-device.service';
import {ElectroDeviceTestingDevice} from '../../models/electro-device-testing-device';
import {FileItem, HttpClientUploadService, InputFileOptions, MineTypeEnum} from '@wkoza/ngx-upload';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import {environment} from '../../../environments';
import {ElectroDeviceCategory} from '../../models/electro-device-category';
import {ElectroDeviceManufacturer} from '../../models/electro-device-manufacturer';
import {ElectroDeviceType} from '../../models/electro-device-type';
import {ElectroDeviceCategoryService} from '../../services/electro-device-category.service';
import {ElectroDeviceTypeService} from '../../services/electro-device-type.service';
import {ElectroDeviceManufacturerService} from '../../services/electro-device-manufacturer.service';
import {NgSelectComponent} from '@ng-select/ng-select';

@Component({
  selector: 'app-testing',
  templateUrl: './testing.component.html',
  styleUrls: ['./testing.component.css']
})
export class TestingComponent implements OnInit, AfterViewInit {

  @ViewChild('instanceForm', {static: true}) instanceForm;
  @ViewChild(ScannerComponent, {static: true})
  @ViewChild('deviceCategorySearchSelect', {static: true}) deviceCategorySearchSelect: NgSelectComponent;
  @ViewChild('deviceTypeSearchSelect', {static: true}) deviceTypeSearchSelect: NgSelectComponent;
  @ViewChild('deviceManufacturerSearchSelect', {static: true}) deviceManufacturerSearchSelect: NgSelectComponent;

  barcodeScanner: ScannerComponent;

  createWizardStep = 0; // 0 = Search Barcode
  createWizardType = 0; // 0 = NEW, 1 = Existing Device (per barcode)
  createWizardStepWaiting = false;
  createWizardDone = false;
  createWizardBarcodeNotFound = false;

  currentTestedAllDevices = [];

  exporting = false;
  exportInfoReady = false;
  exportFile = null;

  exportCSVModalOptions = {
    title: 'Geprüfte-Geräte Exportieren',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Export',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
    },
    preConfirm: () => {
    },
    allowOutsideClick: () => !this.exporting
  };

  table: Table = {
    columnMode: ColumnMode.force,
    selected: [],
    page: new Page(),
    loading: false,
    columnList: [
      {name: 'Gerätehersteller', prop: 'deviceManufacturer', width: 20},
      {name: 'Gerätemodell', prop: 'deviceModel', width: 20},
      {name: 'Gerätekategorie', prop: 'deviceType', width: 20},
      {name: 'Seriennummer', prop: 'serialNumber', width: 20},
    ],
    rowList: new Array<ElectroDeviceTesting>()
  };

  createWaiting = false;
  selectedTesting: ElectroDeviceTesting = null;
  selectedTestingItemToDelete: ElectroDeviceTestingItem = null;
  selectedTestingItemToEdit: ElectroDeviceTestingItem = null;
  selectedDeviceTestingToDelete: ElectroDeviceTesting = null;

  testingList: ElectroDeviceTesting[] = [];
  testingDeviceList: ElectroDeviceTestingDevice[] = [];
  testingListLoading = false;
  isTableView = false;
  newElectroDeviceTesting = new ElectroDeviceTesting();
  newElectroDeviceTestingItem = new ElectroDeviceTestingItem();
  editElectroDeviceTestingItem = new ElectroDeviceTestingItem();

  isBarcodeExistInTesting = false;
  isCSVUpload = false;

  createTestingItemModalOptions = {
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Export',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {

    },
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createWaiting = true;
        this.createTestingItem(resolve);
        this.createWaiting = false;
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };


  editTestingItemModalOptions = {
    title: 'Geprüftes Gerät bearbeiten',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Speichern',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {

    },
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createWaiting = true;
        this.updateTestingItem(resolve);
        this.createWaiting = false;
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  createAllModalOptions = {
    title: 'Geräte Prüfen',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Export',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '100%',
    onOpen: function () {

    },
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createWaiting = true;
        this.createTesting(resolve);
        this.createWaiting = false;
      });
    },
    allowOutsideClick: () => true
  };

  createTestingModalOptions = {
    title: 'Neue Prüfung hinzufügen',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Export',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {

    },
    preConfirm: () => {
      return new Promise((resolve) => {
        this.createWaiting = true;
        this.createTesting(resolve);
        this.createWaiting = false;
      });
    },
    allowOutsideClick: () => !this.createWaiting
  };

  testNormTypeList = [
    {id: 1, name: 'DIN EN 50699 (VDE 0702)', value: true},
    {id: 2, name: 'DIN EN 62353 (VDE 0751-1)', value: false},
    {id: 3, name: 'DIN VDE 105-100', value: false},
    {id: 4, name: 'DIN EN 50678 (VDE 0701)', value: false},
    {id: 5, name: 'ÖVE/ÖNORM E 8701-1', value: false},
  ];
  testTypeList = [
    {id: 1, name: 'Elektrische Geräte und Betriebsmittel', value: true},
    {id: 2, name: 'Medizinische (elektrische) Geräte', value: false},
    {id: 3, name: 'Elektrische Anlagen', value: false},
  ];
  nextTestingDateList = [
    {id: 1, name: '3 Monate', value: false},
    {id: 2, name: '6 Monate', value: false},
    {id: 3, name: '1 Jahr', value: true},
    {id: 4, name: '2 Jahre', value: false},
    {id: 5, name: '3 Jahre', value: false},
    {id: 6, name: '4 Jahre', value: false}
  ];
  testingResultList = [
    {id: 0, name: 'Nicht Bestanden', value: false},
    {id: 1, name: 'Bestanden', value: true},
  ];

  yesNoList = [
    {id: 0, name: 'Ja', value: true},
    {id: 1, name: 'Nein', value: false},
  ];

  protectionClassList = [
    {id: 0, name: 'Schutzklasse I'},
    {id: 1, name: 'Schutzklasse II'},
    {id: 2, name: 'Schutzklasse III'},
  ];

  controlTypeList = [
    {id: 0, name: '220V'},
    {id: 1, name: '400V'},
  ];

  testingDevices = [
    {id: 0, name: 'Benning ST 725', value: false},
    {id: 1, name: 'Benning ST 755+', value: false},
  ];

  commentList = [
    {id: 1, name: 'Vordefinierte Meldung 1'},
    {id: 2, name: 'Vordefinierte Meldung 2'},
    {id: 3, name: 'Manuelle Eingabe'},

  ];
  // Create new testing


  barcodeValue: string;
  companyListLoading = false;
  selectedCompanyId = null;
  companyList: Array<Company> = [];
  displayCamera = false;
  searchStatus = 0;
  device: ElectroDeviceInventoryItem = null;

  buildingList: any;
  buildingCacheList = [];
  selectedBuildingId = null;
  buildingListLoading = false;
  isBarcodeUnlocked = true;
  barcodeSearching = false;

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];
  uploadAllTriggered = false;

  imageUploadModalOptions = {
    title: 'Bild Hochladen',
    cancelButtonColor: '#111',
    showCancelButton: false,
    showConfirmButton: false,
    showLoaderOnConfirm: true,
    focusCancel: true,
    allowOutsideClick: () => false,
    preConfirm: () => {
    },
    onClose: () => {
      this.galleryImages = [];
      this.fileUploader.removeAllFromQueue();
    },
    width: '1000px'
  };

  imageGalleryModalOptions = {
    cancelButtonColor: '#111',
    showCancelButton: false,
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    width: '700px',
  };

  imageOptionsInput: InputFileOptions = {
    multiple: true,
    accept: [MineTypeEnum.Image, MineTypeEnum.Image_Png],
    disableMultipart: false
  };

  importing = false;

  importModalOptions = {
    title: 'Import geprüfte Geräte',
    cancelButtonColor: '#111',
    showCancelButton: false,
    cancelButtonText: 'Abbrechen',
    confirmButtonColor: '#71c016',
    confirmButtonText: 'Importieren',
    showConfirmButton: false,
    showLoaderOnConfirm: false,
    focusCancel: true,
    width: '800px',
    onOpen: function () {
      this.fileUploader.queue = [];
      this.pdfUploader.queue = [];
    },
    preConfirm: () => {
    },
    allowOutsideClick: () => !this.importing,
  };
  isBarcodeExist = false;

  deviceTypeList: Array<ElectroDeviceType>;
  deviceTypeListLoading = false;

  deviceCategoryList: Array<ElectroDeviceCategory>;
  deviceCategoryListLoading = false;

  deviceManufacturerList: Array<ElectroDeviceManufacturer>;
  deviceManufacturerListLoading = false;


  constructor(public fileUploader: HttpClientUploadService,
              private authenticationService: AuthenticationService,
              private toastr: ToastrService,
              public readonly swalTargets: SwalPartialTargets,
              private companyService: CompanyService,
              private buildingService: BuildingService,
              private electroDeviceTestingService: ElectroDeviceTestingService,
              private electroDeviceTestingItemService: ElectroDeviceTestingItemService,
              private electroDeviceTestingDeviceService: ElectroDeviceTestingDeviceService,
              private electroDeviceCategoryService: ElectroDeviceCategoryService,
              private electroDeviceTypeService: ElectroDeviceTypeService,
              private electroDeviceManufacturerService: ElectroDeviceManufacturerService) {
  }

  ngAfterViewInit(): void {
    // this.barcodeScanner.start();
  }

  ngOnInit(): void {

    this.electroDeviceTestingService.events$.forEach(event => {
      if (event === 'refresh-item-list') {
        this.getItems(0);
      }
    });

    this.getTestings();
    this.loadCompanyList();
    this.loadBuildingList();
    this.loadTestingDeviceList();
    this.getElectroDeviceTypeList();
    this.getElectroDeviceManufacturerList();
    this.getElectroDeviceCategoryList();

    this.galleryOptions = [
      {
        width: '600px',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];

    this.fileUploader.queue = [];
    this.fileUploader.onCancel$.subscribe(
      (data: FileItem) => {

      });

    this.fileUploader.onDropError$.subscribe(
      (err) => {
      });

    this.fileUploader.onAddToQueue$.subscribe((data: any) => {
      if (!this.isCSVUpload) {
        this.uploadImage(data);
      }
    });

    this.fileUploader.onProgress$.subscribe(
      (data: any) => {
      });

    this.fileUploader.onSuccess$.subscribe(
      (data: any) => {

        if (data.body.data.uploadType === 'testing-item') {

        } else {
          this.galleryImages = data.body.data;

          this.table.rowList.forEach(value => {
            value.images.push(data.body.data.fileName);
          });

          data.item.remove();
        }
      }
    );

    this.fileUploader.onAddToQueue$.subscribe(
      () => {

      }
    );

  }

  getTestings() {

    this.testingListLoading = true;

    this.electroDeviceTestingService.getList().subscribe(dataResponse => {

      const data = dataResponse.data;

      if (dataResponse.status === 'success') {
        this.testingList = [...data];
      }

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message);
      }

      this.testingListLoading = false;
    });
  }

  getItems(pageNumber) {

    this.isBarcodeUnlocked = true;

    if ((typeof pageNumber) !== 'number') {
      pageNumber = pageNumber.offset;
    }

    this.table.loading = true;
    this.table.page.pageNumber = pageNumber;
    this.electroDeviceTestingItemService.getList(this.selectedTesting.id, this.table.page).subscribe(dataResponse => {

      if (dataResponse.status === 'success') {
        if (dataResponse.page !== null) {
          this.table.page = dataResponse.page;
        }

        const data = dataResponse.data;

        this.table.rowList = [...data];
      }

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message, 'Error');
      }

      this.table.loading = false;

    });
  }

  loadBuildingList() {
    this.buildingListLoading = true;
    this.buildingService.getNameList().subscribe(resp => {
      this.buildingList = resp.data;
      this.buildingListLoading = false;
    });
  }

  loadCompanyList() {
    this.companyListLoading = true;
    this.companyService.getNameList().subscribe(resp => {
      this.companyList = resp.data;
      this.companyListLoading = false;
    });
  }

  isGranted(role: string, roleType: string = null, rolePermissionType: number = null) {
    return this.authenticationService.isGranted(role, roleType, rolePermissionType);
  }

  createTesting(resolve: (value?: (PromiseLike<any> | any)) => void) {
    this.createWaiting = true;

    this.electroDeviceTestingService
      .create(this.newElectroDeviceTesting)
      .subscribe((dataResponse: DataResponse<ElectroDeviceInventory>) => {

          if (dataResponse.status === 'error') {
            this.toastr.error(dataResponse.message);
          }

          if (dataResponse.status === 'success') {
            this.getTestings();
            this.newElectroDeviceTesting = new ElectroDeviceTesting();
          }

          resolve();
          this.createWaiting = false;
        },
        error => {
          this.toastr.error(error.toString());
          this.createWaiting = false;
        });
  }

  updateTestingItem(resolve: (value?: (PromiseLike<any> | any)) => void) {
    this.createWaiting = true;

    this.electroDeviceTestingItemService
      .update(this.selectedTesting, this.editElectroDeviceTestingItem)
      .subscribe((dataResponse: DataResponse<ElectroDeviceTestingItem>) => {

          if (dataResponse.status === 'error') {
            this.toastr.error(dataResponse.message);
          }

          if (dataResponse.status === 'success') {
            this.getTestings();

            if (this.isTableView) {
              this.getItems(0);
            }

            this.newElectroDeviceTesting = new ElectroDeviceTesting();
          }

          resolve();

          this.createWaiting = false;
        },
        error => {
          this.toastr.error(error.toString());
          this.createWaiting = false;
        });
  }

  createTestingItem(resolve: (value?: (PromiseLike<any> | any)) => void) {
    this.createWaiting = true;

    this.electroDeviceTestingItemService
      .create(this.selectedTesting, this.newElectroDeviceTestingItem)
      .subscribe((dataResponse: DataResponse<ElectroDeviceTestingItem>) => {

          if (dataResponse.status === 'error') {
            this.toastr.error(dataResponse.message);
          }

          if (dataResponse.status === 'success') {
            this.getTestings();
            this.newElectroDeviceTesting = new ElectroDeviceTesting();
          }

          resolve();

          this.createWaiting = false;
        },
        error => {
          this.toastr.error(error.toString());
          this.createWaiting = false;
        });
  }

  onBeforeOpen($event: BeforeOpenEvent) {
  }

  onSearchCompanyChange($event: any) {
    this.selectedCompanyId = $event;
    this.buildingCacheList = this.buildingList.filter((building: Building) => {
      return building.company.id === $event;
    });
  }

  onSearchBuildingChange($event: any) {
    this.selectedBuildingId = $event;
  }

  customSearchFn(term: string, item: any) {
    term = term.toLowerCase();
    return item.name.toLowerCase().indexOf(term) > -1 || item.name.toLowerCase() === term;
  }

  toggleCamera() {
    this.displayCamera = !this.displayCamera;
  }

  padLeft(text: string, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }

  onValueChanges(result: QuaggaJSResultObject): void {
    this.barcodeValue = result.codeResult.code;
  }

  onStarted(event): void {
  }

  checkBarcode(barcode) {

    if (!this.isBarcodeUnlocked) {
      return;
    }

    this.electroDeviceTestingItemService.barcodeCheck(this.selectedTesting, barcode).subscribe(resp => {
      this.isBarcodeExist = resp.data.exists;
    });

    return this.isBarcodeExist;
  }

  search() {
    this.isBarcodeExistInTesting = false;
    this.createWizardStepWaiting = true;
    this.barcodeSearching = true;
    this.electroDeviceTestingItemService.barcodeSearch(this.selectedTesting, this.newElectroDeviceTestingItem).subscribe(resp => {

      this.isBarcodeUnlocked = true;

      if (resp.data.length === 0) {
        const barcode = this.newElectroDeviceTestingItem.barcode;
        this.newElectroDeviceTestingItem = new ElectroDeviceTestingItem();
        this.newElectroDeviceTestingItem.barcode = barcode;
        this.newElectroDeviceTestingItem.nextTestingDateType = 3;
        this.isBarcodeUnlocked = true;
      } else {

        this.newElectroDeviceTestingItem = resp.data;
        if (this.checkBarcode(this.newElectroDeviceTestingItem.barcode)) {

          this.newElectroDeviceTestingItem.barcode = null;
          this.isBarcodeExistInTesting = true;
          return;
        }


        this.isBarcodeExist = true;
        if (!this.newElectroDeviceTestingItem.nextTestingDateType) {
          this.newElectroDeviceTestingItem.nextTestingDateType = 3;
        }
      }
      this.barcodeSearching = false;
      this.createWizardStep = 1;
      this.createWizardStepWaiting = false;
    });
  }

  getTestingStatus(testing: ElectroDeviceTesting) {

    if (testing.status === 0) {
      return 'Offen';
    }

    if (testing.status === 10) {
      return 'In Bearbeitung';
    }

    if (testing.status === 20) {
      return 'Abgebrochen';
    }

    if (testing.status === 30) {
      return 'Abgeschlossen';
    }

    return 'Unbekannt';
  }

  getTestingTestNormType(testing: ElectroDeviceTesting) {

    if (testing.testNormType === 1) {
      return 'DIN EN 50699 (VDE 0702)';
    }

    if (testing.testNormType === 2) {
      return 'DIN EN 62353 (VDE 0751-1)';
    }

    if (testing.testNormType === 3) {
      return 'DIN VDE 105-100';
    }

    if (testing.testNormType === 4) {
      return 'DIN EN 50678 (VDE 0701)';
    }

    if (testing.testNormType === 5) {
      return 'ÖVE/ÖNORM E 8701-1';
    }

    return 'Unbekannt';
  }

  getTestingTestType(testing: ElectroDeviceTesting) {
    if (testing.testType === 1) {
      return 'Elektrische Geräte und Betriebsmittel';
    }

    if (testing.testType === 2) {
      return 'Medizinische (elektrische) Geräte';
    }

    if (testing.testType === 3) {
      return 'Elektrische Anlagen';
    }

    return 'Unbekannt';
  }

  getTestingDateList(typeId) {
    return this.nextTestingDateList[typeId].name;
  }

  openTestingView(testing: ElectroDeviceTesting) {
    this.selectedTesting = testing;
    this.getItems(0);
    this.isTableView = true;
  }

  closeTableView() {

    this.selectedTesting = null;
    this.isTableView = false;
  }

  setTestingStatus(status, testing: ElectroDeviceTesting) {
    this.electroDeviceTestingService
      .setStatus(status, testing)
      .subscribe((dataResponse: DataResponse<ElectroDeviceInventory>) => {

          if (dataResponse.status === 'error') {
            this.toastr.error(dataResponse.message);
          }

          if (dataResponse.status === 'success') {
            this.getTestings();
          }
        },
        error => {
          this.toastr.error(error.toString());
        });
  }

  removeTesting(selectedTestingToDelete: ElectroDeviceTesting) {

  }

  onActivate(event) {

  }

  onSelect({selected}) {
    this.table.selected.splice(0, this.table.selected.length);
    this.table.selected.push(...selected);
  }

  onDelete(rowList) {
  }

  onSort(event) {
    this.table.page.sortColumn = event.column.prop;
    this.table.page.sortOrder = event.newValue;
    this.getItems(0);
  }

  canDisplayTestingButton(testing: ElectroDeviceTesting, selectedNumber: number) {
    if (selectedNumber === 0) { // Abgeschlossen
      return testing.status === 0 || testing.status === 10;
    }

    if (selectedNumber === 1) { // Abgeschlossen
      return testing.status === 30;
    }

    if (selectedNumber === 2) { // Cancelled
      return testing.status === 20;
    }

    return false;
  }

  resetModal(testing: ElectroDeviceTesting) {
    this.selectedTesting = testing;
    this.createWizardStep = 0;
    this.createWizardStepWaiting = false;
    this.createWizardDone = false;
    this.createWizardBarcodeNotFound = false;
    this.newElectroDeviceTestingItem = new ElectroDeviceTestingItem();
    this.isBarcodeUnlocked = true;
    this.fileUploader.cancelAll();
  }

  gotoWizardStep(number: number) {
    this.createWizardStep = number;
    if (this.createWizardStep === 0) {
      this.resetModal(this.selectedTesting);
    }
  }

  formatBarcode(barcode: any) {
    return ('000000' + barcode).slice(-6);
  }

  private loadTestingDeviceList() {

    const page = new Page();

    page.pageNumber = 0;
    page.size = 100;

    this.electroDeviceTestingDeviceService.getList(page).subscribe(dataResponse => {

      const data = dataResponse.data;

      if (dataResponse.status === 'success') {
        this.testingDeviceList = [...data];
      }

      if (dataResponse.status === 'error') {
        this.toastr.error(dataResponse.message);
      }
    });
  }

  displayRegisterNumber(testingDeviceId: number) {

    let isRequired = false;

    this.testingDeviceList.forEach(function (testingDevice) {
      if (testingDevice.id === testingDeviceId) {
        isRequired = testingDevice.isRegisterNumberRequired;
        return testingDevice.isRegisterNumberRequired;
      }
    });

    return isRequired;
  }

  canCreate(newElectroDeviceTestingItem: ElectroDeviceTestingItem) {

    let isRegisterNumberOk = false;


    this.testingDeviceList.forEach(function (testingDevice) {
      if (testingDevice.id === newElectroDeviceTestingItem.testingDeviceId) {
        if (!testingDevice.isRegisterNumberRequired ||
          (testingDevice.isRegisterNumberRequired && newElectroDeviceTestingItem.registerNumber)) {
            isRegisterNumberOk = true;
        }
      }
    });

    return newElectroDeviceTestingItem.deviceManufacturer &&
      newElectroDeviceTestingItem.deviceCategory &&
      newElectroDeviceTestingItem.deviceModel &&
      newElectroDeviceTestingItem.deviceType &&
      newElectroDeviceTestingItem.barcode &&
      newElectroDeviceTestingItem.nextTestingDateType &&
      newElectroDeviceTestingItem.testingDeviceId &&
      ((!newElectroDeviceTestingItem.isTestOk && newElectroDeviceTestingItem.comment.trim().length > 0) || newElectroDeviceTestingItem.isTestOk === true) &&
      isRegisterNumberOk && !this.isBarcodeExist;
  }

  cloneDevice() {
    this.isBarcodeExist = false;
    this.isBarcodeUnlocked = true;
    this.newElectroDeviceTestingItem.barcode = '';
    this.newElectroDeviceTestingItem.serialNumber = '';
    this.newElectroDeviceTestingItem.comment = '';
  }

  delete() {
    const that = this;
    this.electroDeviceTestingItemService.delete(this.selectedTestingItemToDelete.id).subscribe(resp => {

      if (resp.status === 'success') {
        this.getItems(0);
      } else {
        that.toastr.error(resp.message);
      }
    });

  }

  searchRemote($event: KeyboardEvent) {
    if ($event.code === 'Enter') {
      this.getItems(0);
    }
  }

  deleteTesting() {

    const that = this;

    this.electroDeviceTestingService.delete(this.selectedDeviceTestingToDelete.id).subscribe(resp => {

      if (resp.status === 'success') {
        this.getTestings();
      } else {
        that.toastr.error(resp.message);
      }
    });
  }


  uploadImage(item: FileItem) {
    item.upload({
      method: 'POST',
      url: this.electroDeviceTestingItemService.uploadImageUrl(this.newElectroDeviceTestingItem.id)
    });
  }

  uploadImageAll() {
    this.uploadAllTriggered = true;
    this.fileUploader.uploadAll({
      method: 'POST',
      url: this.electroDeviceTestingItemService.uploadImageUrl(this.newElectroDeviceTestingItem.id)
    });
  }

  activeRemoveAllBtn(): boolean {
    return this.fileUploader.queue.some(item => (item.isReady || item.isCancel || item.isError));
  }

  activeUploadAllBtn(): boolean {
    return this.fileUploader.queue.some(item => (item.isReady));
  }

  activeCancelAllBtn(): boolean {
    return this.fileUploader.queue.some((item: FileItem) => item.uploadInProgress);
  }


  getReportName(row: any) {

    const vedin = row.vedin;
    const buildingNumber = this.padLeft(row.electroDeviceInventory.building.internalNumber, '0', 2);
    const customerNumber = row.electroDeviceInventory.building.company.customerNumber;
    const barcode = row.barcode;

    return `${environment.apis.backend}/media/uploads/devices/testings/${vedin}_${customerNumber}_${buildingNumber}_${barcode}.pdf`;
  }

  export(type: string) {

    this.exportFile = null;
    this.exporting = true;

    const self = this;

    if (type === 'csv') {
      setTimeout(function () {
        self.exportInfoReady = true;
      }, 2000, self);

      this.electroDeviceTestingItemService.export(this.selectedTesting).subscribe(resp => {
        this.exporting = false;
        this.exportFile = resp.data.fileName;
      });

    }
  }

  getExportFile() {
    return this.exportFile ? `${environment.apis.backend}/media/temp/reports/${this.exportFile}` : 'no-file-found';
  }

  cancelExport() {
    this.swalTargets.cancelButton().click();
    this.exporting = false;
    this.exportFile = null;
  }

  removeImage(itemFile: FileItem) {
    itemFile.remove();
  }

  checkStatus(testing: ElectroDeviceTesting) {
  }

  checkTesting(testing: ElectroDeviceTesting) {
    this.electroDeviceTestingService.checkTesting(testing)
      .subscribe((dataResponse: DataResponse<ElectroDeviceTesting>) => {

          if (dataResponse.status === 'error') {
            this.toastr.error(dataResponse.message);
          }

          if (dataResponse.status === 'success') {
            this.getTestings();
          }
        },
        error => {
          this.toastr.error(error.toString());
        });
  }

  onSearchDeviceCategoryListChange($event: any) {

  }

  onSearchDeviceTypeListChange($event: any) {

  }

  onSearchDeviceManufacturerListChange($event: any) {

  }

  private getElectroDeviceCategoryList() {

    this.deviceCategoryListLoading = true;
    this.electroDeviceCategoryService.getList(new Page()).subscribe(value => {
      this.deviceCategoryList = [...value.data];
      this.deviceCategoryListLoading = false;
    });
  }

  private getElectroDeviceManufacturerList() {

    this.deviceManufacturerListLoading = true;
    this.electroDeviceManufacturerService.getList(new Page()).subscribe(value => {
      this.deviceManufacturerList = [...value.data];
      this.deviceManufacturerListLoading = false;
    });
  }

  private getElectroDeviceTypeList() {

    this.deviceTypeListLoading = true;
    this.electroDeviceTypeService.getList(new Page()).subscribe(value => {
      this.deviceTypeList = [...value.data];
      this.deviceTypeListLoading = false;
    });
  }

  setBackgroundColor(item: ElectroDeviceInventoryItem) {
    return item.isDGUVV3Passed ? '#e9fde9' : '#fde9e9';
  }

  isTestedItemChecked(item: ElectroDeviceInventoryItem, i: number, checked: boolean) {

    const index = this.selectedTesting.testedItems.indexOf(item);
    this.selectedTesting.testedItems.splice(index, 1);

    this.electroDeviceTestingItemService.checkDeviceTestingItem(this.selectedTesting, item).subscribe(value => {
      console.dir(value);
    });

  }
}
